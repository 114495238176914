import React from 'react';
import ReactDOM from 'react-dom';
import './edit_metadata.scss';
import { ViewWrapperForManageMetadataComponent } from 'common/dsmapi/ManageMetadataComponent';
import { DatasetLicenses } from 'common/dsmapi/ManageMetadataComponent/types';
import { View } from 'common/types/view';

const props = {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  metadata: window.initialState!.view as View,
  onSave: () => {},
  datasetLicenses: window.initialState?.datasetLicenses as DatasetLicenses,
  onClose: () => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    window.location.assign(window.initialState!.pathToAsset!);
    return null;
  },
  isModal: false
};

window.addEventListener('load', function () {
  ReactDOM.render(
    <ViewWrapperForManageMetadataComponent {...props} />,
    document.querySelector('[data-react-component="Metadata"]')
  );
});
